import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth0 } from "@auth0/auth0-react";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';

import { useSelector } from 'react-redux';
import { selectedPicture } from '../../app/authSlice';

import { Menu as AppMenu } from '../';

export const Header = () => {

  const { logout } = useAuth0();
  const [ isMenuOpen, setMenuOpen ] = useState()

  const toggleMenu = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setMenuOpen(!isMenuOpen)
  };  
  
  const navigate = useNavigate()
  
  const [ anchorElUser, setAnchorElUser ] = useState();
  const avatar = useSelector(selectedPicture);
  return (
    <>
      <AppMenu open={isMenuOpen} toggle={toggleMenu} />
      <AppBar position="static">      
        <Toolbar disableGutters sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <IconButton onClick={toggleMenu}>
            <MenuIcon />
          </IconButton>
          <IconButton onClick={(event) => setAnchorElUser(!anchorElUser ? event.currentTarget: null)}>
            {
              avatar &&
                (
                  <>
                    <Avatar alt="Remy Sharp" src={avatar} />
                    <Menu
                      sx={{ mt: '45px' }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={anchorElUser ? true : false}
                      onClose={() => {setAnchorElUser(null)}}
                    >
                      <MenuItem onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                        <ListItemIcon>
                          <LogoutIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Logout</ListItemText>                        
                      </MenuItem>
                    </Menu>
                  </>
                )                            
            }            
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  )
}
