import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../Header/Header';
import { CustomForm } from '../../components/CustomForm';

import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react'

import { useUpdateMutation } from '../../api';

export const Edit = () => {
  const navigate = useNavigate()
  const { id, text } = useParams()

  console.log(text)

  const [ accessToken, setAccessToken ] = useState('')

  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently()
	setAccessToken(token)
      } catch (e) {
        console.log(e)
      }
    }
    getAccessToken()
  }, [])  
  
  const [ update, { isLoading: isUpdating }] = useUpdateMutation()

  
  const submit = (text) => {
    const body = {
      text,
    }
    update({id, payload: body, accessToken})
      .unwrap()
      .then(() => {
        navigate('/list')
      })
      .then((error) => {
        console.log(error)
      })
  }
  return (
    <>
      <Header/>
      <CustomForm label="Update" submit={submit} pname={text} pid={id}/>
    </>
  )
}
