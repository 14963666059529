import { useState } from 'react';

import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

import { useNavigate } from 'react-router-dom';

export const CustomForm = ({pname, pid, label, submit}) => {
  const navigate = useNavigate();
  const [ text, setText ] = useState(pname || '')

  const onSubmit = (event) => {
    event.preventDefault();
    submit(text);
  }
  
  return (
    <Box
      onSubmit={onSubmit}
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      sx={{ flexGrow: 1, margin: 2 }}
      autoComplete="off"
    >
      <Grid container spacing={5}>
        <Grid md={12} display="flex" justifyContent="center" alignItems="center">
	  <TextField fullWidth id="outlined-basic" label="Text" variant="outlined" value={text} onChange={(event)  => setText(event.target.value)} multiline rows={4}/>
        </Grid>
        <Grid md={12} display="flex" justifyContent="center" alignItems="center">
          <Button sx={{mr: 2}} color="success" variant="contained" type="submit"> { label } </Button>
          <Button sx={{ml: 2}} color="warning" variant="contained" type="button" onClick={() => navigate('/')}> Cancel</Button>
        </Grid>
      </Grid>      
    </Box>    
  )
}
