import { useState, Fragment } from 'react'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import ListIcon from '@mui/icons-material/List';
import AddIcon from '@mui/icons-material/Add';

import { useNavigate } from 'react-router-dom';

export const Menu = ({toggle, open}) => {
  const navigate = useNavigate()
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={toggle}
    >
      <List>        
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/list')}>            
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Contexts" />
          </ListItemButton>          
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate('/create')}>            
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="New" />
          </ListItemButton>          
        </ListItem>        
      </List>      
    </Drawer>      
  );
}
