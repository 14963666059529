import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../Header/Header';

import { useAuth0 } from '@auth0/auth0-react'

import { useCreateMutation } from '../../api';
import { CustomForm } from '../../components/CustomForm';

export const Create = () => {

  const [ accessToken, setAccessToken ] = useState('')

  const { getAccessTokenSilently } = useAuth0()
  
  const navigate = useNavigate()
  const [ create ] = useCreateMutation();

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently()
	setAccessToken(token)
      } catch (e) {
        console.log(e)
      }
    }
    getAccessToken()
  }, [])    
  
  const submit = (text) => {
    const body = {
      text
    }
    create(body, accessToken)
      .unwrap()
      .then(() => {
        navigate('/list')
      })
      .then((error) => {
        console.log(error)
      })
  }
  return (
    <>
      <Header />
      <CustomForm label="Create" submit={submit} />
    </>
  )
}
