import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const API = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState()).auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    }
  }),
  endpoints: (builder) => ({
    getAll: builder.query({
      query: (accessToken) => {
        return {
          url: '/',
          method: 'GET',
	  headers: {Authorization: `Bearer ${accessToken}`}
        }
      },
      providesTags: [`${process.env.REACT_APP_TAG}s`]
    }),
    create: builder.mutation({
      query: (payload, accessToken) => {
        return {
          url: '/',
          method: 'POST',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
	    Authorization: `Bearer ${accessToken}`
          },
        }
      },
      invalidatesTags: [`${process.env.REACT_APP_TAG}s`]
    }),
    delete: builder.mutation({
      query: (id, token) => {
        return {
          url: `/${id}`,
          method: 'DELETE',
	  headers: {Authorization: `Bearer ${token}`}
        }
      },
      invalidatesTags: [`${process.env.REACT_APP_TAG}s`]
    }),
    update: builder.mutation({
      query: ({id, payload,  accessToken}) => {
        return {
          url: `/${id}`,
          method: 'PUT',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
	    Authorization: `Bearer ${accessToken}`
          },	  
        }
      },
      invalidatesTags: [`${process.env.REACT_APP_TAG}s`]
    })    
  })
})

export const { useGetAllQuery, useCreateMutation, useDeleteMutation, useUpdateMutation } = API;
